<template lang="">
  <section class=" ">
    <div
      v-for="(item, index) in rawData"
      :key="index"
      class="py-5  "
      :class="index > 0 && `border-t-2 border-gray-100`"
    >
      <div class="px-5">
        <div class="flex items-start justify-between mb-3">
          <h3 class="text-xl font-semibold w-75 pr-2">{{ item.title }}</h3>
          <p class="w-25 text-gray-500 text-sm font-semibold">
            {{ toDisplayDate(item.release_date) }}
          </p>
        </div>
        <div v-for="(note, noteIndex) in item.release_notes" :key="noteIndex">
          <h4 class="text-base font-semibold pl-5" v-if="note.data.length">
            {{ getNoteTitle(note.value) }}
          </h4>
          <div class="pl-10" v-if="note.data.length">
            <ul class="list-disc">
              <li
                v-for="(data, dataIndex) in note.data"
                :key="dataIndex"
                class="text-sm"
              >
                {{ data.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { choiceMapping } from '@/views/whats-new/shared/helper.js'
import Helper from '@/components/layout/topbar/notification-dropdown-trigger-components/helper.js'
export default {
  props: {
    rawData: {
      type: Array,
    },
  },
  data() {
    return {
      releaseChoices: choiceMapping,
    }
  },
  methods: {
    toDisplayDate: Helper.toDisplayDate,
    getNoteTitle(type) {
      return this.releaseChoices[type]
    },
  },
}
</script>
<style lang=""></style>
